<template>
  <Layout>
    <BHeader>
      <el-form
        ref="form"
        :inline="true"
        :model="formData"
      >
        <el-form-item prop="title">
          <el-input
            v-model="formData.title"
            clearable
            placeholder="名称/ID"
          />
        </el-form-item>
        <el-form-item prop="status">
          <el-select
            v-model="formData.status"
            placeholder="上架状态"
            clearable
          >
            <el-option
              v-for="(item, index) in appModuleStatesList"
              :key="index"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="handleSearch"
          >
            搜索
          </el-button>
        </el-form-item>
      </el-form>
      <template v-slot:right>
        <el-button
          type="primary"
          @click="handleCreateAD"
        >
          新建
        </el-button>
      </template>
    </BHeader>
    <el-table
      :data="advertisementListInfo.list"
      stripe
      style="width: 100%"
    >
      <el-table-column
        prop="weight"
        label="排序"
        min-width="100"
      />
      <el-table-column
        prop="key"
        label="ID"
        min-width="70"
      />
      <el-table-column
        prop="title"
        min-width="100"
        label="名称"
      />
      <el-table-column
        prop="image"
        min-width="120"
        label="图"
      >
        <template v-slot="{row: { image }}">
          <div
            class="game-cover"
            :style="{
              backgroundImage: `url(${image})`
            }"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="desc"
        min-width="130px"
        label="描述"
      />
      <el-table-column
        prop="tag"
        min-width="100px"
        label="Tag"
      >
        <template v-slot="{row}">
          {{ advertisementTagMap[row.tag].name || 'UNKNOWN' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="status"
        min-width="100px"
        label="上架状态"
      >
        <template v-slot="{row}">
          {{ row.status === 1 ? '上架': '下架' }}
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        width="120"
        label="操作"
      >
        <template v-slot="{row}">
          <BTextButton
            title="编辑"
            icon="fa fa-pencil"
            @click="handleEditAD(row)"
          />
          <BTextButton
            :loading="row.loading"
            :title="row.status === 1 ? '启用' : '禁用'"
            :icon="`fa ${row.status === 1 ? 'fa-check-circle' : 'fa-ban' }`"
            @click="handleToggleAD(row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <!--    <Pagination-->
    <!--      :total="advertisementListInfo.total"-->
    <!--      :page.sync="formData.pageNum"-->
    <!--      :limit.sync="formData.limit"-->
    <!--      @pagination="queryAdvertisementList(formData)"-->
    <!--    />-->
  </Layout>
</template>

<script>
import { appModuleStatesList, advertisementTagMap } from '@/utils/selectOptions'
import { mapActions, mapState } from 'vuex'
import { toggleAdvertisementStatus } from '@/api/APP'

export default {
  name: 'AdvertisementList',
  data () {
    return {
      appModuleStatesList,
      advertisementTagMap,
      groupKey: '',
      formData: {
        status: '',
        title: '',
        pageNum: 1,
        groupKey: ''
      }
    }
  },
  computed: {
    ...mapState('appManage', ['advertisementListInfo'])
  },
  created () {
    this.formData.groupKey = this.$route.query.groupKey
    this.queryAdvertisementList(this.formData)
  },
  methods: {
    ...mapActions('appManage', ['queryAdvertisementList']),
    handleSearch () {
      this.formData.pageNum = 1
      this.queryAdvertisementList(this.formData)
    },
    handleCreateAD () {
      this.$router.push({
        name: 'AdvertisementEdit',
        query: {
          groupKey: this.formData.groupKey
        }
      })
    },
    handleEditAD (row) {
      this.$router.push({
        name: 'AdvertisementEdit',
        query: {
          key: row.key
        }
      })
    },
    handleToggleAD (row) {
      if (row.status !== 1) {
        this.$confirm('此操作将启用， 是否继续?', '提示', { type: 'warning ' })
          .then(() => {
            this.$set(row, 'loading', true)
            toggleAdvertisementStatus({
              key: row.key,
              status: 1
            }).then((res) => {
              if (res.code === 200) {
                this.$set(row, 'status', 1)
              }
            }).finally(() => {
              this.$set(row, 'loading', false)
            })
          })
      } else {
        this.$confirm('此操作将禁用， 是否继续?', '提示', {
          type: 'warning ',
          confirmButtonText: '好的',
          showCancelButton: false
        }).then(() => {
          this.$set(row, 'loading', true)
          toggleAdvertisementStatus({
            key: row.key,
            status: 2
          }).then((res) => {
            if (res.code === 200) {
              this.$set(row, 'status', 2)
            }
          }).finally(() => {
            this.$set(row, 'loading', false)
          })
        })
      }
    }
  }
}
</script>

<style scoped lang="less">
  .game-cover{
    width: 96px;
    height: 50px;
    background-size: cover;
    background-position: center;
    border-radius: 4px;
  }
</style>
